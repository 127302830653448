p.p1 {
    margin: 0.0px 0.0px 0.0px 0.0px;
    font: 20.0px ;
    color: #000000
}

p.p2 {
    margin: 0.0px 0.0px 0.0px 0.0px;
    font: 10.0px Arial;
    color: #1c4697
}

span.s1 {
    color: #000000
}

span.s2 {
    font: 7.0px Arial
}

span.s3 {
    font: 10.0px Helvetica
}
.ul1{
    padding-left: 30px;
}